import { useEffect } from "react";
import "./ocean.css";
import {
  Scene,
  PerspectiveCamera,
  PlaneGeometry,
  MeshStandardMaterial,
  BoxGeometry,
  Vector3,
  Mesh,
  TextureLoader,
  ACESFilmicToneMapping,
  WebGLRenderer,
  RepeatWrapping,
  PMREMGenerator,
  MathUtils,
  PointLight,
} from "three";

import { Water } from "./water";
import { Sky } from "./sky";

function Ocean() {
  useEffect(() => {
    let container;
    let camera: any, scene: any, renderer: any;
    let water: any, sun: any, mesh: any;
    let pause = false;

    init();
    animate();

    function init() {
      container = document.getElementById("container");
      if (!container) throw new Error("No container");

      renderer = new WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.toneMapping = ACESFilmicToneMapping;
      renderer.toneMappingExposure = 0.5;
      container.appendChild(renderer.domElement);
      scene = new Scene();
      camera = new PerspectiveCamera(
        55,
        window.innerWidth / window.innerHeight,
        1,
        20000
      );
      camera.position.set(0, 34, 100);
      sun = new Vector3();

      const waterGeometry = new PlaneGeometry(10000, 10000);

      water = new Water(waterGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: new TextureLoader().load(
          "/water.jpg",
          function (texture) {
            texture.wrapS = texture.wrapT = RepeatWrapping;
          }
        ),
        sunDirection: new Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x001e0f,
        distortionScale: 3.7,
        fog: scene.fog !== undefined,
      });

      water.rotation.x = -Math.PI / 2;
      scene.add(water);

      const sky: any = new Sky();
      sky.scale.setScalar(10000);
      scene.add(sky);

      const skyUniforms = sky.material.uniforms;

      skyUniforms["turbidity"].value = 10;
      skyUniforms["rayleigh"].value = 2;
      skyUniforms["mieCoefficient"].value = 0.005;
      skyUniforms["mieDirectionalG"].value = 0.8;

      const parameters = {
        elevation: 1.2,
        azimuth: 150,
      };

      const pmremGenerator = new PMREMGenerator(renderer);
      let renderTarget: any;

      function updateSun() {
        const phi = MathUtils.degToRad(90 - parameters.elevation);
        const theta = MathUtils.degToRad(parameters.azimuth);

        sun.setFromSphericalCoords(1, phi, theta);

        sky.material.uniforms["sunPosition"].value.copy(sun);
        water.material.uniforms["sunDirection"].value.copy(sun).normalize();

        if (renderTarget !== undefined) renderTarget.dispose();

        renderTarget = pmremGenerator.fromScene(sky);

        scene.environment = renderTarget.texture;
      }

      updateSun();

      const geometry = new BoxGeometry(36, 26, 0.1);
      const texture = new TextureLoader().load("/whale.png");
      const material = new MeshStandardMaterial({
        roughness: 0,
        transparent: true,
        opacity: 1.0,
        map: texture,
      });

      const light = new PointLight(0xffffff, 110110, 110110110110);
      light.position.set(-100, 0, 11);
      scene.add(light);

      mesh = new Mesh(geometry, material);
      scene.add(mesh);

      window.addEventListener("resize", onWindowResize);
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function animate() {
      requestAnimationFrame(animate);
      render();
    }

    document.querySelector("#toggle")?.addEventListener("click", () => {
      pause = !pause;
    });

    function render() {
      if (pause) return;
      const time = performance.now() * 0.001;
      mesh.position.y = Math.sin(time * 2) * 5 + 15;
      water.material.uniforms["time"].value += 0.5 / 60.0;
      renderer.render(scene, camera);
    }
  });

  return (
    <div className="ocean-container">
      <div className="ocean" id="container"></div>
    </div>
  );
}

export default Ocean;
