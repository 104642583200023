import "./App.css";
import Ocean from "./components/ocean/ocean";
import { getDailyTip } from "./functions/get-daily-tip";
import { clsx } from "clsx";

const getHostAndPath = (url: string) => {
  try {
    const urlObj = new URL(url);
    return `${urlObj.host}${urlObj.pathname}`;
  } catch (error) {
    return url;
  }
};

function App() {
  const tip = getDailyTip();
  const short = tip.content.length < 42;
  const long = tip.content.length > 100;
  return (
    <div className="App">
      <div className="content">
        <header>
          <h1 className="heading">Accessibility Tip of the Day</h1>
        </header>
        <main>
          <figure>
            <blockquote>
              <p className={clsx("tip", short && "short", long && "long")}>
                {tip.content}
              </p>
            </blockquote>
            {/* Source */}
            {tip.href ? (
              <figcaption className="source">
                <strong>
                  {tip.text || "Source"}:<br />
                </strong>
                <cite>
                  <a href={tip.href} target="_black">
                    {getHostAndPath(tip.href)}
                  </a>
                </cite>
              </figcaption>
            ) : (
              <p className="source">{tip.text}</p>
            )}
          </figure>
        </main>
      </div>
      <Ocean />
      <footer>
        <p className="made-by">
          Made with ❤️ by Bou dev team to Elisa, our accessibility guru
        </p>
        <button className="pause" id="toggle" type="button">
          || Toggle background animation
        </button>
      </footer>
    </div>
  );
}

export default App;
