export interface Tip {
  content: string;
  href?: string;
  text?: string;
}

// List of web accessibility tips and links to sources
const tips: Tip[] = [
  {
    content: "Text should have contrast ratio that is at least 4.5:1",
    href: "https://webaim.org/articles/contrast/#ratio",
  },
  {
    content: "Large text contrast ratio should be at least 3:1",
    href: "https://webaim.org/articles/contrast/#ratio",
  },
  {
    content:
      "Use semantic HTML-elements for their correct purposes as much as possible",
    href: "https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML",
  },
  {
    content: "83% of website home pages fail to have enough contrast for text",
    href: "https://webaim.org/projects/million/",
  },
  {
    content:
      "Avoid complex language and unnecessary jargon for your website content. ",
    href: "https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML",
  },
  {
    content: "Least accessible category of websites is adult content ",
    href: "https://webaim.org/projects/million/#categories",
  },
  {
    content:
      "If your img-element is just for decoration, alt-attribute should be an empty string.",
    href: "https://webaim.org/techniques/alttext/",
  },
  {
    content:
      'Creating custom style for checkbox often requires adding hidden <input type="checkbox" /> element',
    href: "https://css-tricks.com/the-checkbox-hack/",
  },
  {
    content:
      "Alternaltive text (alt) is textual substitute for non text content. Often used with images.",
    href: "https://webaim.org/techniques/alttext/",
  },
  {
    content:
      "It’s not just about disabled users being able to access your website — it’s about everyone being able to access your website.",
    text: "TRENTON MOSS, Owner of Webcredible Consultancy Firm, UK",
  },
  {
    content:
      "Web Accessibility is not only for people with disabilities but for all of us who are thankful that there are no barriers in using our abilities.",
    text: "REMBERTO ESPOSA JR., First President of Philippine Web Accessibility Group",
  },
  {
    content:
      "We want to showcase and provide awards-recognition for certain websites — and the developers and designers who make them — which shatter the misconception that accessible web sites are boring and basic.",
    text: "MIKE CHERIM, Founder, Accessites.org – The Art of Accessibility",
  },
  {
    content:
      "Many of the modern aria attributes are not best option since older devices might not support them.",
    text: "Just a common fact",
  },
  {
    content:
      'Create a "skip to main content" button by by creating a link that targets main element and is only visible when focused',
    href: "https://webaim.org/techniques/css/invisiblecontent#skipnavlinks",
  },
  {
    content:
      "With css display:none and visibility: hidden the content is hidden visually and also for screen readers ",
    href: "https://webaim.org/techniques/css/invisiblecontent/#techniques",
  },
  {
    content:
      "By the year 2060 the number of elderly people in the is expected to double to 98 million. ",
    href: "https://ddiy.co/web-accessibility-statistics/",
  },
  {
    content: "96.8% of home pages had detectable WCAG 2 failures.",
    href: "https://ddiy.co/web-accessibility-statistics/",
  },
  {
    content:
      "Web Content Accessibility Guidelines (WCAG) is a set of globally adobted standards for web developers, designers and content creators that aim to make websites and apps accessible for everyone.",
    href: "https://wcag.com/resource/what-is-wcag/",
  },
  {
    content:
      "Website should be usable without a mouse. Make sure that links, buttons and other functionalities have distinct and clear focus-states for keyboard users.",
    href: "https://wcag.com/developers/2-1-1-keyboard/",
  },
  {
    content: "84% of home pages have low-contrast text.",
    href: "https://ddiy.co/web-accessibility-statistics/",
  },
  {
    content:
      "90% of websites are inaccessible to people with disabilities who use assistive technologies.",
    href: "https://ddiy.co/web-accessibility-statistics/",
  },
  {
    content:
      "Add a keyboard operable pause button to autoplaying animations and videos.",
    href: "https://developer.mozilla.org/en-US/docs/Learn/Accessibility/Multimedia",
  },
  {
    content:
      "Users can enable a setting to reduce motion or disable animations on their devices. The prefers-reduced-motion CSS media feature can be used to detect the user's preference and minimize non-essential movement on the website.",
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion#user_preferences",
  },
];

export const getDailyTip = () => {
  // For each day return a tip from the list that does not repeat in the same order each month
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();
  const tipIndex = (day + month + year) % tips.length;
  return tips[tipIndex];
};
